import React, { useState, useEffect } from 'react';
import images from '../../utils/GalleryData';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import {
  headingAnimation,
  sectionBodyAnimation,
} from '../../hooks/useAnimation';
import { BottomLine } from '../../components';
import './Gallery.css';
import Lightbox from 'react-18-image-lightbox';
import 'react-18-image-lightbox/style.css';
import { Link, useLocation } from 'react-router-dom';
import { FaAngleRight } from 'react-icons/fa';

const Gallery = () => {
  const [ref, inView] = useInView();
  const [viewDiv, setViewDiv] = useState(false);
  const animation = useAnimation();

  const [tag, setTag] = useState('Semua Proyek');
  const [filteredImages, setFilteredImages] = useState(images);

  const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
  const [lightboxIndex, setLightboxIndex] = useState(0);

  const location = useLocation();

  const openLightbox = (index) => {
    setLightboxIndex(index);
    setLightboxIsOpen(true);
  };

  useEffect(() => {
    if (inView) {
      setViewDiv(true);
    } else {
      setViewDiv(false);
    }
    if (location.pathname === '/' && filteredImages.length > 4) {
      setFilteredImages(filteredImages.slice(0, 4));
    }
  }, [inView, animation, location, filteredImages]);

  useEffect(() => {
    if (tag === 'Semua Proyek') {
      setFilteredImages(images);
    } else {
      setFilteredImages(images.filter((image) => image.tag === tag));
    }
  }, [tag]);

  return (
    <div className={`${location.pathname !== '/' && 'pt-16'}`}>
      <div className="py-16 parent bg-yellow-300 ">
        <motion.div
          initial="hidden"
          animate={viewDiv && 'visible'}
          variants={headingAnimation}
        >
          <div className="mb-6">
            <h3 className="text-center text-accent">
              Apa saja yang telah kami buat?
            </h3>
            <h1 className="text-4xl font-semibold text-center">
              Hasil <span className="text-primary">Karya kami</span>
            </h1>
            <BottomLine />
            <h2 className="py-5 text-center text-accent">
              Kami fokus memberikan solusi holistik bagi bisnis Anda dalam
              membangun brand Anda secara Efektif & Konsisten melalui berbagai
              saluran Digital. Kami akan membantu Anda dalam mengembangkan
              bisnis sehingga tercapai tujuan Perusahaan Anda dengan lebih
              Efektif.
            </h2>
          </div>
        </motion.div>

        <motion.div
          ref={ref}
          initial="hidden"
          animate={viewDiv && 'visible'}
          variants={sectionBodyAnimation}
        >
          <div className="tags">
            <TagButton
              name="Semua Proyek"
              handleSetTag={setTag}
              tagActive={tag === 'Semua Proyek'}
            />
            <TagButton
              name="Digital Printing"
              handleSetTag={setTag}
              tagActive={tag === 'Digital Printing'}
            />
            <TagButton
              name="Signage"
              handleSetTag={setTag}
              tagActive={tag === 'Signage'}
            />
            <TagButton
              name="Interior Design"
              handleSetTag={setTag}
              tagActive={tag === 'Interior Design'}
            />
          </div>
          <div className="container">
            {filteredImages.map((image, index) => (
              <div key={image.id} className="image-card">
                <img
                  className="image"
                  src={image.imageName}
                  alt=""
                  onClick={() => openLightbox(index)}
                />
              </div>
            ))}
          </div>
          {lightboxIsOpen && (
            <Lightbox
              mainSrc={filteredImages[lightboxIndex].imageName} // Tentukan gambar besar
              nextSrc={
                filteredImages[(lightboxIndex + 1) % filteredImages.length]
                  .imageName
              } // Gambar selanjutnya
              prevSrc={`/images/${
                filteredImages[
                  (lightboxIndex + filteredImages.length - 1) %
                    filteredImages.length
                ].imageName
              }`} // Gambar sebelumnya
              onCloseRequest={() => setLightboxIsOpen(false)}
              onMovePrevRequest={() =>
                setLightboxIndex(
                  (lightboxIndex + filteredImages.length - 1) %
                    filteredImages.length
                )
              }
              onMoveNextRequest={() =>
                setLightboxIndex((lightboxIndex + 1) % filteredImages.length)
              }
            />
          )}
        </motion.div>
        {location.pathname === '/' && (
          <div className="mt-8 text-right">
            <Link
              to="/gallery"
              className="duration-300 text-1xl hover:text-primary"
            >
              <button className="primary-button ">
                <span>Lihat Semua</span>
                <span>
                  <FaAngleRight />
                </span>
              </button>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

const TagButton = ({ name, handleSetTag, tagActive }) => {
  return (
    <button
      className="btn btn-sm bg-primary border-2 border-primary text-white hover:bg-transparent hover:border-primary hover:text-primary duration-300 mx-3 my-3 sm:my-0"
      onClick={() => handleSetTag(name)}
    >
      {name}
    </button>
  );
};

export default Gallery;

import React from 'react';
import logoSS from '../../../assets/images/SS-LOGO.png';
import { Link } from 'react-router-dom';
import {
  FaPhoneAlt,
  FaFacebookSquare,
  FaTiktok,
  FaInstagramSquare,
} from 'react-icons/fa';
import { IoLogoWhatsapp } from 'react-icons/io';
import { MdEmail } from 'react-icons/md';
import { ButtonWa } from '../../../components';

const Footer = () => {
  return (
    <div>
      <footer
        className="p-20 footer text-base-100"
        style={{ backgroundColor: '#e5e7eb' }}
      >
        <div>
          <Link to="/">
            <img src={logoSS} alt="SURABAYA STUDIO" className="w-32 sm:w-46" />
          </Link>
          <h1 className="text-2xl font-bold text-black ">SURABAYA STUDIO</h1>
          <span className="text-accent">
            Ruko Arcade G-02, <br />
            Jl. Citraland, Ds. Lateri, Kec. Baguala, <br />
            Kota Ambon Maluku, 97231.
          </span>
          <div className="flex items-center my-1">
            <FaPhoneAlt className="mr-6 text-2xl text-accent"></FaPhoneAlt>
            <h3 className="font-medium text-accent">+6281343300121</h3>
          </div>
          <div className="flex items-center my-1">
            <IoLogoWhatsapp className="mr-6 text-2xl text-accent"></IoLogoWhatsapp>
            <h3 className="font-medium text-accent">+6282199882353</h3>
          </div>
          <div className="flex items-center my-1">
            <MdEmail className="mr-6 text-2xl text-accent"></MdEmail>
            <h3 className="font-medium text-accent">
              surabayastudiocenter@gmail.com
            </h3>
          </div>
        </div>
        {/* Tautan */}
        <div>
          <span className="font-bold text-black">Tautan</span>
          <Link
            to="/"
            className="link link-hover hover:text-primary text-accent"
          >
            <span>Beranda</span>
          </Link>
          <Link
            to="/tentang"
            className="link link-hover hover:text-primary text-accent"
          >
            <span>Tentang</span>
          </Link>
          <Link
            to="/layanan"
            className="link link-hover hover:text-primary text-accent"
          >
            <span>Layanan</span>
          </Link>
          <Link
            to="/kontak"
            className="link link-hover hover:text-primary text-accent"
          >
            <span>Kontak</span>
          </Link>
          <Link
            to="/blog"
            className="link link-hover hover:text-primary text-accent"
          >
            <span>Blog</span>
          </Link>
        </div>
        {/* Fokus & Layanan */}
        <div>
          <span className="font-bold text-accent">Informasi</span>
          <div className="text-accent">
            <div className="flex space-x-2 justify-center items-center">
              <span className="text-green-500 font-bold">Senin:</span>
              <p>buka 09:00 - 17:00</p>
            </div>
          </div>
          <div className="text-accent">
            <div className="flex space-x-2 justify-between items-center">
              <span className="text-green-500 font-bold">Selasa:</span>
              <p>buka 09:00 - 17:00</p>
            </div>
          </div>
          <div className="text-accent">
            <div className="flex space-x-2 justify-between items-center">
              <span className="text-green-500 font-bold">Rabu:</span>
              <p>buka 09:00 - 17:00</p>
            </div>
          </div>
          <div className="text-accent">
            <div className="flex space-x-2 justify-between items-center">
              <span className="text-green-500 font-bold">Kamis:</span>
              <p>buka 09:00 - 17:00</p>
            </div>
          </div>
          <div className="text-accent">
            <div className="flex space-x-2 justify-between items-center">
              <span className="text-green-500 font-bold">Jumat:</span>
              <p>buka 09:00 - 17:00</p>
            </div>
          </div>
          <div className="text-accent">
            <div className="flex space-x-2 justify-between items-center">
              <span className="text-green-500 font-bold">Sabtu:</span>
              <p>buka 09:00 - 16:00</p>
            </div>
          </div>
          <div className="text-accent">
            <div className="flex space-x-2 justify-between items-center">
              <span className="text-red-500 font-bold">Minggu:</span>
              <p>tutup</p>
            </div>
          </div>
          <div className="text-accent">
            <div className="flex space-x-2 justify-between items-center">
              <span className="text-red-500 font-bold">Tanggal Merah:</span>
              <p>tutup</p>
            </div>
          </div>
        </div>
        <div>
          <span className="font-bold text-accent text-center">
            Ikuti Media Sosial Kami
          </span>
          <div className="flex items-center mt-2">
            <a
              href="https://www.facebook.com/profile.php?id=100076332352420"
              target="blank"
              className="text-2xl text-accent hover:text-primary hover:-translate-y-1.5 shadow-lg mx-1 duration-300"
            >
              <FaFacebookSquare size={35}></FaFacebookSquare>
            </a>
            <a
              href="https://www.instagram.com/surabaya.studio/"
              target="blank"
              className="text-2xl text-accent hover:text-primary hover:-translate-y-1.5 shadow-lg mx-1 duration-300"
            >
              <FaInstagramSquare size={35}></FaInstagramSquare>
            </a>
            <a
              href="https://www.tiktok.com/@surabaya_studio_ambon"
              target="blank"
              className="text-2xl text-accent hover:text-primary hover:-translate-y-1.5 shadow-lg mx-1 duration-300"
            >
              <FaTiktok size={35}></FaTiktok>
            </a>
          </div>
        </div>
      </footer>
      <footer className="px-10 py-6 text-sm text-center text-base-100 border-base-300 bg-neutral ">
        <div className="w-full h-[2px] bg-gray-600"></div>
        <div className="flex flex-col items-center justify-center mt-6 md:flex-row text-accent">
          <p>&copy; 2023 ALL RIGHTS RESERVED | SURABAYA STUDIO</p>
        </div>
      </footer>
      <ButtonWa />
    </div>
  );
};

export default Footer;

import Aldi from "../assets/images/People/Aldi.jpg"
import Dadang from "../assets/images/People/Dadang.jpg"
import Gilbert from "../assets/images/People/Gilbert.jpg"

const TimPeople = [
    {
        id: 1,
        title: "Aldi Ramdani",
        icon: <img src={Aldi} alt="Aldi Ramdani MSN PRODUCTION" />,
        description:
            "Desainer",
    },
    {
        id: 2,
        title: "Jefri Khunawan",
        icon: <img src={Dadang} alt="Jefri Khunawan MSN PRODUCTION" />,
        description:
            "Pimpinan Perusahaan / Owner",
    },
    {
        id: 3,
        title: "Gilbert Hutapea",
        icon: <img src={Gilbert} alt="Gilbert Hutapea MSN PRODUCTION" />,
        description:
            "Staf",
    },
];

export default TimPeople;

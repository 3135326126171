/* eslint-disable import/no-anonymous-default-export */
import alfamart from '../assets/images/gambarKlien/alfamart.png';
import conch from '../assets/images/gambarKlien/conch.png';
import hypermart from '../assets/images/gambarKlien/hypermart.png';
import indomaret from '../assets/images/gambarKlien/indomaret.png';
import kfc from '../assets/images/gambarKlien/kfc.png';
import mandiri from '../assets/images/gambarKlien/mandiri.png';
import pizza from '../assets/images/gambarKlien/pizza.jpg';
import telkomsel from '../assets/images/gambarKlien/telkomsel.png';
export default [
  {
    id: 1,
    tag: 'Digital Printing',
    imageName: alfamart,
  },
  {
    id: 2,
    tag: 'Digital Printing',
    imageName: conch,
  },
  {
    id: 3,
    tag: 'Digital Printing',
    imageName: hypermart,
  },
  {
    id: 4,
    tag: 'Signage',
    imageName: indomaret,
  },
  {
    id: 5,
    tag: 'Signage',
    imageName: kfc,
  },
  {
    id: 6,
    tag: 'Signage',
    imageName: mandiri,
  },
  {
    id: 7,
    tag: 'Interior Design',
    imageName: pizza,
  },
  {
    id: 8,
    tag: 'Interior Design',
    imageName: telkomsel,
  },
];

import { BiSolidMessageAltCheck, BiSolidCalendarCheck } from 'react-icons/bi'
import { PiPhonePlusFill } from 'react-icons/pi'
import { FaAward } from 'react-icons/fa6'

const Services = [
  {
    id: 1,
    title: "Respon Cepat",
    icon: <BiSolidMessageAltCheck />,
    description:
      "Kami sangat menghargai waktu Anda. Oleh karena itu, kami berupaya agar segala informasi Anda dapatkan secara cepat.",
  },
  {
    id: 2,
    title: "Konsultasi Gratis",
    icon: <PiPhonePlusFill />,
    description:
      "Gratis konsultasi untuk desain, bahan, durasi pengerjaan, titik pemasangan, berbagai hal seputar advertising dan percetakan.",
  },
  {
    id: 3,
    title: "Jaminan Tepat Waktu",
    icon: <BiSolidCalendarCheck />,
    description:
      "Dengan team yang professional dan berpengalaman, kami yakin dapat melakukan pekerjaan sesuai dengan waktu yang telah disepakati.",
  },
  {
    id: 4,
    title: "Garansi Pekerjaan",
    icon: <FaAward />,
    description:
      "Kami menerapkan Ceklist Standar Kualitas (Quality Control) secara rutin pada setiap item pekerjaan demi menjaga kepuasan pelanggan.",
  },
];

export default Services;